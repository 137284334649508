const catalog = document.querySelectorAll('.catalog__list');
const modalPreview = document.querySelector('.modal--preview');

const priceMesurement = '<span>€/&nbsp;м<sup>2</sup></span>';

function getModalTempalate() {
  const img = modalPreview.querySelector('img');
  const name = modalPreview.querySelector('.modal__img-name');
  const price = modalPreview.querySelector('.modal__img-price');

  return {
    img,
    name,
    price,
  };
}

function clear() {
  if (modalPreview) {
    const modal = getModalTempalate(modalPreview);

    modal.img.innerHTML = '';
    modal.name.innerHTML = '';
    modal.price.innerHTML = '';
  }
}

function create(target) {
  const modal = getModalTempalate(modalPreview);

  const targetImg = target.querySelector('img');
  const targetName = target.querySelector('.product-card__name').textContent;
  const targetPrice = target.querySelector('.product-card__price span').textContent;

  modal.img.src = targetImg.src;
  modal.img.width = targetImg.width;
  modal.img.height = targetImg.height;
  modal.img.alt = targetImg.alt;

  modal.name.textContent = targetName;

  if (+targetPrice && targetPrice !== 0) {
    modal.price.textContent = targetPrice;
    modal.price.nextElementSibling.innerHTML = priceMesurement;
  } else {
    modal.price.nextElementSibling.innerHTML = '';
    modal.price.textContent = targetPrice;
  }

}

function onImageClick(evt) {
  const target = evt.target.parentElement.closest('li');

  if (modalPreview) {
    getModalTempalate(modalPreview);
    clear(modalPreview);
    create(target);
  }
}

function showPreview() {
  if (catalog) {
    catalog.forEach((item) => {
      item.addEventListener('click', onImageClick);
    });
  }
}

export {showPreview};
