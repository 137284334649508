const playVideoOnScroll = () => {
  const media = document.querySelectorAll('[data-play="scroll"]');

  if (media.length) {
    media.forEach((el) => {
      const options = {
        root: null, // it is the viewport //
        threshold: 0.8, // it is the percent of displayed page from 0 to 1 //
        rootMargin: '0px 0px 0px 0px', // viewport margins //
      };

      const sectionObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            el.play();
          } else {
            el.pause();
          }
        });
      }, options);

      sectionObserver.observe(el);
    });
  }
};

export {
  playVideoOnScroll
};
