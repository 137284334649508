const filter = document.querySelector('.filter');
const catalogCards = document.querySelectorAll('.product-card');

const activeClass = 'js-is-active';
const hiddenClass = 'js-hidden-card';
const all = 'all';


function toggleHiddenClass() {
  const activeFilterLinks = filter.querySelectorAll(`.${activeClass} .filter__link`);
  let visibleCards = [];

  catalogCards.forEach((card) => {
    card.classList.add(hiddenClass);
  });

  activeFilterLinks.forEach((el) => {
    catalogCards.forEach((card) => {
      if (el.dataset.stones === all) {
        card.classList.remove(hiddenClass);
        visibleCards.push(card);
      }

      if (card.dataset.type === el.dataset.stones) {
        visibleCards.push(card);
      }
    });
  });

  visibleCards.forEach((card) => {
    card.classList.remove(hiddenClass);
  });
}

function onFilterClick(evt) {
  evt.preventDefault();

  const {target} = evt;
  const parent = target.parentElement;

  const hasAllAttribute = target.dataset.stones === all;
  const hasActiveClass = parent.classList.contains(activeClass);

  if (!filter || !target.dataset.stones) {
    return;
  }

  const activeFilters = filter.querySelectorAll(`.${activeClass}`);
  let activeFiltersLength;

  if (hasAllAttribute && hasActiveClass) {
    /**
     * если это фильтр ВЫБРАТЬ ВСЕ и он активный
     * ничего не делать
     */
    return;
  } else if (hasAllAttribute && !hasActiveClass) {
    /**
     * если это фильтр ВЫБРАТЬ ВСЕ и он НЕактивный
     * выбрать его,
     * удалить все остальные активные фильтры,
     * удалить hiddenClass у всех карточек товаров
     * согласно данному фильтру
     */
    activeFilters.forEach((item) => {
      item.classList.remove(activeClass);
    });
    parent.classList.add(activeClass);

    toggleHiddenClass();
  }

  // если это НЕ фильтр ВЫБРАТЬ ВСЕ
  if (!hasAllAttribute) {
    // если активен ВЫБРАТЬ ВСЕ - удалить его
    filter.querySelector(`[data-stones="${all}"]`).parentElement.classList.remove(activeClass);

    activeFiltersLength = filter.querySelectorAll(`.${activeClass}`).length;

    /**
     * если НЕактивен ВЫБРАТЬ ВСЕ
     * + клик по одному(1) УЖЕ активному фильтру.
     * + все остальные сейчас неактивны
     */
    if (hasActiveClass && activeFiltersLength === 1) {
      return;
    }
    /**
     * иначе сделать активным/удалить фильтр
     */
    parent.classList.toggle(activeClass);
    toggleHiddenClass();
  }
}

function toggleFilter() {
  if (filter) {
    filter.addEventListener('click', onFilterClick);
  }
}

export {toggleFilter};
