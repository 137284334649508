const forms = document.querySelectorAll('.modal__app-form');

function setFormSubmitEvent() {
  if (forms) {
    forms.forEach((form) => {
      const submitClass = form.querySelector('.application-form__success.form-success');

      form.addEventListener('submit', (evt) => {
        setTimeout(() => {
          evt.preventDefault();
          submitClass.style.zIndex = '1';
        }, 350);
      });
    });
  }
}

export {setFormSubmitEvent};
