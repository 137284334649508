import {disableScrolling, enableScrolling} from '../utils/scroll-lock';
import {isTablet} from '../utils/responsive';
import {debounce} from '../utils/debounce';

export default () => {
  const header = document.querySelector('.header');
  const menu = document.querySelector('.main-nav');


  if (!header || !menu) {
    return;
  }

  const headerLinks = header.querySelectorAll('.js-move-to');

  const hiddenClass = 'is-hidden';
  const openClass = 'is-open-menu';
  const activeClass = 'active';

  const menuBtn = header.querySelector('.hamburger-menu__btn');
  const writeToUsIcon = header.querySelector('.write-to-us[data-write="icon"]');

  const top = document.documentElement.offsetTop;

  let isClose = true;

  function checkCurrentPosition() {
    return document.documentElement.getBoundingClientRect().top < top;
  }

  function hide() {
    if (menu) {
      menu.classList.add(hiddenClass);
      header.classList.remove(openClass);
      menuBtn.parentElement.classList.remove(hiddenClass);
      menuBtn.classList.remove(activeClass);
    }

    if (checkCurrentPosition()) {
      menuBtn.parentElement.classList.remove(hiddenClass);
    }
  }

  function show() {
    if (menu) {
      menu.classList.remove(hiddenClass);
      header.classList.add(openClass);

      if (!checkCurrentPosition()) {
        menuBtn.parentElement.classList.add(hiddenClass);
      }
    }
  }

  function toggleWriteToUsIconVisible() {
    if (isTablet() && checkCurrentPosition() && !header.classList.contains(openClass)) {
      writeToUsIcon.classList.remove(hiddenClass);
    } else {
      writeToUsIcon.classList.add(hiddenClass);
    }
  }

  function toggleMenuVisibility() {
    if (checkCurrentPosition() || isTablet()) {
      hide();
      writeToUsIcon.classList.add(hiddenClass);
    } else {
      show();
      writeToUsIcon.classList.remove(hiddenClass);
    }
  }

  toggleMenuVisibility();

  function headerScrollHandler() {
    debounce(toggleWriteToUsIconVisible(), 500);

    if (!isTablet()) {
      menuBtn.classList.remove('active');
      menuBtn.classList.add('not-active');
      debounce(toggleMenuVisibility(), 500);
    }
  }

  function windowChangeSizeWhatcher() {
    if (isTablet() || header.classList.contains(openClass)) {
      hide();

      if (document.body.classList.contains('scroll-lock')) {
        enableScrolling();
      }
    }
    window.removeEventListener('resize', windowChangeSizeWhatcher);
  }

  // Скрываем меню на мобильных и откываем при клике на бургер-иконку

  function changeIsCloseMenuFlag() {
    isClose = !isClose;
  }

  function toggleScrollLock() {
    if (header.classList.contains(openClass)) {
      disableScrolling();
    } else {
      enableScrolling();
    }
  }

  function menuClickHandler() {
    menu.classList.toggle(hiddenClass);
    header.classList.toggle(openClass);
    menuBtn.classList.toggle('active');
    menuBtn.classList.toggle('not-active');

    changeIsCloseMenuFlag();

    if (isTablet()) {
      toggleScrollLock();
    }
  }

  document.addEventListener('scroll', headerScrollHandler);
  window.addEventListener('resize', debounce(windowChangeSizeWhatcher, 300));
  window.addEventListener('orientationchange', windowChangeSizeWhatcher);
  menuBtn.addEventListener('click', menuClickHandler);

  if (headerLinks.length) {
    headerLinks.forEach((link) => {
      link.addEventListener('click', menuClickHandler);
    });
  }
};
