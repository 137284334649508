import {debounce} from '../utils/debounce';
import {isTablet} from '../utils/responsive';

export default () => {
  const aside = document.querySelector('.sidebar');
  const header = document.querySelector('header.header');
  const wrapper = document.querySelector('.wrapper');

  if (!aside || isTablet()) {
    return;
  }

  const asideWrapper = aside.querySelector('.sidebar__wrapper');

  function getHeaderHeight() {
    return header.getBoundingClientRect().height;
  }

  function setAsideTopPositionHandler() {
    if (isTablet()) {
      return;
    } else {
      const headerHeight = getHeaderHeight();

      asideWrapper.style.top = `${headerHeight}px`;
      wrapper.style.overflowX = 'visible';
    }
  }

  document.addEventListener('DOMContentLoaded', setAsideTopPositionHandler);

  window.addEventListener('resize', debounce(setAsideTopPositionHandler, 1000));
};
