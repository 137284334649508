function addPhoneMask() {
  const phones = document.querySelectorAll('input[type="tel"]');

  /* eslint-disable */
  if (phones) {
    phones.forEach((phone) => {
      let patternMask = IMask(phone, {
        /* eslint-enable */
        mask: '+{7}{ (000) }{000}-{00}-{00}',
      });
    });
  }
}

export {addPhoneMask};
